import {BehaviorSubject} from "rxjs";
import {Article, IArticle} from "../interfaces/article";
import {StorageService} from "./storage.service";
import {ErrorToast} from "../helpers/default-toast";
import {i18n} from "./i18n.service";
import {IEnv} from "../interfaces/env";
import {AlertConfirm} from "../helpers/alert-utils";
class ShoppingCartServiceController {
  private shoppingCartCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private shoppingCart$: BehaviorSubject<IArticle[]> = new BehaviorSubject<IArticle[]>([]);
  private shoppingCartInternal = [];
  public shoppingCart = this.shoppingCart$.asObservable();
  public shoppingCartCount = this.shoppingCartCount$.asObservable();

  constructor() {
    const shoppingCart = StorageService.get('shoppingCart');
    this.shoppingCartInternal = shoppingCart?.map((article:IArticle) => {
      return new Article().deserialize(article);
    }) || [];
    this.shoppingCart$.next(this.shoppingCartInternal)
    this.shoppingCartCount$.next(this.shoppingCartInternal?.length || 0);
  }

  addArticle(article: IArticle, amount = 1) {
    // Find an article with the same `connect_id` and identical `selectedAdditionalOptions`
    const index = this.shoppingCartInternal.findIndex(article$ =>
        article$.connect_id === article.connect_id &&
        JSON.stringify(article$.selectedAdditionalOptions) === JSON.stringify(article.selectedAdditionalOptions)
    );

    if (index !== -1) {
      // If an identical article exists, update its amount
      if (this.shoppingCartInternal[index].selectedAdditionalOptions){
        this.shoppingCartInternal[index].amount += amount;
      }else{
        this.shoppingCartInternal[index].amount = amount
      }
    } else {
      // Add the article as a new entry
      article["amount"] = amount;

      // If the article has `selectedAdditionalOptions`, ensure amount is always 1
      if (article.selectedAdditionalOptions && article.selectedAdditionalOptions.length > 0) {
        article["amount"] = 1;
      }

      this.shoppingCartInternal.push(article);
    }

    // Update the shopping cart state
    StorageService.set('shoppingCart', this.shoppingCartInternal);
    this.shoppingCart$.next(this.shoppingCartInternal);
    this.shoppingCartCount$.next(this.shoppingCartInternal?.length || 0);
  }



  getArticle(article: IArticle){
    return this.shoppingCartInternal.find(article$ => article$.connect_id === article.connect_id);
  }

  async changeAmount(article: IArticle, amount: number,environment:IEnv) {
    if (environment.privateShop || amount <= article.stock) {
      if (amount <= 0) {
        await ShoppingCartService.removeArticle(article)
      } else {
        ShoppingCartService.addArticle(article, amount);
      }
    } else {
      await ErrorToast(i18n(`There are only ${article.stock} units of this item available.`)
          .t(`de`, `Es sind nur noch ${article.stock} Einheiten dieses Artikels verfügbar`).get(),5000)    }
  }

  getAllArticleCount() {
    return this.shoppingCartInternal.reduce((total, article) => total + article.amount, 0);
  }

  getArticleLength() {
    return this.shoppingCartInternal.length;
  }

  getRemainingArticlesCount(maxCount:number) {
    const totalAmount = this.getAllArticleCount();

    const remainingCount = maxCount - totalAmount;
    return remainingCount > 0 ? remainingCount : 0; // Ensures no negative values
  }


  canIncreaseWithCoupon(maxOrderArticleCount:number) {
    const totalAmount = this.getAllArticleCount();
    const distinctArticleCount = this.getArticleLength();
    if (totalAmount <= maxOrderArticleCount) {
      return !(distinctArticleCount >= maxOrderArticleCount || totalAmount >= maxOrderArticleCount);
    }
    return true;
  }

  async removeArticle(articleToRemove: IArticle) {
    return new Promise<void>((resolve, reject) => {
      AlertConfirm(
          i18n('Delete Article').t('de', 'Artikel entfernen').get(),
          i18n(`Are you sure you want to remove this article?`).t('de', `Möchten Sie diesen Artikel wirklich entfernen?`).get(),
          () => {
            // Remove the exact item based on its unique properties
            this.shoppingCartInternal = this.shoppingCartInternal.filter(article => {
              return !(article.connect_id === articleToRemove.connect_id &&
                  JSON.stringify(article.selectedAdditionalOptions) === JSON.stringify(articleToRemove.selectedAdditionalOptions));
            });

            // Update the shopping cart state
            StorageService.set('shoppingCart', this.shoppingCartInternal);
            this.shoppingCart$.next(this.shoppingCartInternal);
            this.shoppingCartCount$.next(this.shoppingCartInternal?.length || 0);
            resolve(); // Resolve the promise when done
          },
          () => {
            reject(); // Reject in case of cancel
          },
          i18n("Remove").t('de', 'Entfernen').get(),
          i18n("Cancel").t('de', 'Abbrechen').get(),
      );
    });
  }


  clear(){
    this.shoppingCartInternal = [];
    StorageService.set('shoppingCart', this.shoppingCartInternal);
    this.shoppingCart$.next(this.shoppingCartInternal)
    this.shoppingCartCount$.next(this.shoppingCartInternal?.length || 0);
  }


}


export const ShoppingCartService = new ShoppingCartServiceController();
